import React, { useState, useEffect } from 'react'
import '_appSetup/Global.css'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Grid, Tab } from '@material-ui/core'
import { SEO, View, Text, Page } from 'lib'


const Dashboard = (props) => {
  const styles = useStyles(props)

  return (
    <Page>
      <SEO title='Dashboard'/>
      <View>
        <View style={styles.topWrapper}>

          <Grid item xs={12}>
            <Text variant='h6' style={styles.shutdownDisclaimer}>Account closed</Text>
          </Grid>

        </View>

      </View>
    </Page>
  )
}


const useStyles = makeStyles((theme) => ({
  topWrapper: {
    minHeight: '25vh',
    paddingTop: '50px',
    paddingBottom: '30px',
    width: '100%',
    ...theme.center,
    display: 'flex',
    flexDirection: 'column',
  },
  APYwrapper: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '15vh',
      paddingTop: '30px',
      paddingBottom: '20px',
    },
  },
  depositButton: {
    marginTop: theme.spacing(1.5),
  },
  referralCTAButton: {
    marginTop: theme.spacing(0.5),
  },
  newDepositNote: {
    opacity: 0.5,
    textAlign: 'center',
  },
  bottomWrapper: {
    ...theme.center,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(8),
  },
  performanceWrapper: {
    maxWidth: '532px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  spaceBottom: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  perfLabel: {
    // fontSize: 12,
  },
  perfSpacer: {
    marginTop: theme.spacing(3),
    borderTop: '1px solid #00000016',
    paddingTop: theme.spacing(8),
    alignSelf: 'stretch',
  },
  tableSpacer: {
    borderTop: '1px solid #00000016',
    alignSelf: 'stretch',
    paddingTop: theme.spacing(2),
  },
  value: {
    fontSize: 18,
  },
  expectedInterest: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
  earnExtra: {
    fontSize: 12,
  },
  referralBox: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(6),
  },
  mobilePerformanceWrapper: {
    marginLeft: `${theme.spacing(2)}px !important`,
    marginRight: `${theme.spacing(2)}px !important`,
    marginBottom: `${theme.spacing(6)}px !important`,
  },
  mobilePerformanceContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
  },
  shutdownDisclaimer: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  withdrawButton: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
}))

export default Dashboard
